<template>
    <!-- <section class="container-fluid wa-bottom-mob">
        <div class='row'>
			
            <a id="whatsapp-mobile" data-bs-toggle="modal" data-bs-target="#exampleModal"><div  class="col-md-12 wa-button-mob"  style=" font-family: Montserrat;"><b>WHATSAPP SEKARANG!</b></div></a>
        </div>
		
    </section> -->
    <section class="container-fluid float-wa" style="background-color: #F6F6F6;">
        <!-- <div class="container " style="background-color: #F6F6F6;">			 -->
			<div class="row ">
				<div class="col-4 mx-0 ps-3 text-center">
					<img src="../assets/images/Ellipse 6.png" class="img-fluid img-user" alt="">
				</div>
				<div class="col-8 mx-0 pe-3 mb-2 text-start">
					<span>Dody Setiawan</span>
					<img src="../assets/images/wa-promo-ahm.png" alt="" id="whatsapp-mobile" class="img-fluid mt-1" width="100%" data-bs-toggle="modal" data-bs-target="#exampleModal" >
				</div>
			</div>
		<!-- </div>  -->
		
    </section>
</template>

<script>

export default {
  name:'ButtonWhatsapp',
  components: {

  },
}
</script>


<style>
.img-user{
	/* position: absolute; */
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
.float-wa{
	display: none;
}
@media screen and (max-width: 768px) {
	.float-wa {
	display: block;
	}
}
.float-wa{
	z-index: 100;
	height: 87px;
	width:100%;
	background-color: #F6F6F6;
	position: fixed;
	bottom: 0px;
	/* left: 0; */
	padding: 10px;
	/* text-align: center; */
}
/* .wa-bottom-mob {
	display: none;
}
.wa-button-mob {
	height: 45px;
	width:100%;
	background-color: #F6F6F6;
	position: fixed;
	z-index: 20;
	bottom: 0;
	left: 0;
	padding: 10px;
	text-align: center;
	font-size: 16px;
	animation-name: ukuranfont; 
	animation-duration: 1s; 
	animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	
}
a .wa-button-mob {
	color: #FFF;
}
@keyframes ukuranfont {
	0%   {font-size: 16px;}
	12.5%   {font-size: 16.5px;}
	25%   {font-size: 17px;}
	37.5%   {font-size: 17.5px;}
	50%   {font-size: 18px;}
	62.5%   {font-size: 17.5px;}
	75%   {font-size: 17px;}
	87.5%   {font-size: 16.5px;}
	100%   {font-size: 16px;}
}
@media screen and (max-width: 768px) {
	.wa-bottom-mob {
	display: block;
	}
} */
</style>