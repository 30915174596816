import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/event_promo',
        name: 'New_Promo',
        component: () =>
            import ('../views/NewandPromo.vue')

    },
    {
        path: '/event_promo/:slug',
        name: 'detail_event_promo',
        props: true,
        component: () =>
            import ('../views/DetailEvent.vue')
    },
    {
        path: '/tipe_unit',
        name: 'Residential',
        component: () =>
            import ('../views/Residential.vue')

    },
    {
        path: '/units_name/:slug',
        name: 'detail_units',
        props: true,
        component: () =>
            import ('../views/DetailUnits.vue')
    },
    {
        path: '/fasilitas',
        name: 'Fasilitas',
        component: () =>
            import ('../views/Fasilitas.vue')

    },


    {
        path: "/event_promo/detail",
        name: 'detail-event',
        props: true,
        component: () =>
            import ('../views/DetailEvent.vue')

    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router