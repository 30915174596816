<template>
  <div id="app">
        <Navbar/>
        <br>
        <div class="mt-5">
            <ButtonWhatsapp/>
            <router-view/>
        </div>
        <Footer/>
  
    
  </div>
</template>

<script>
import ButtonWhatsapp from './components/ButtonWhatsapp.vue'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'


export default {
  components: { ButtonWhatsapp, Navbar, Footer },
  
}


</script>


<style>

.imgNav{
    width: 100px;
    height: auto;
}

#app {
  font-family: raleway;
  font-weight: 700;
  text-align: left;
  
}

.pita {
    background-image:url('./assets/image/pita.svg');
    background-repeat: no-repeat;
}

.pita1{
    font-family: 'Montserrat';
font-weight: 300;
font-size: 15px;
display: flex;
color: #FFFFFF;
}

.pita2{
    font-family: 'Montserrat';
font-weight: 600;
font-size: 16px;
display: flex;
color: #FFFFFF;

}

 
    .ccarousel{
        background-color: #0D424B;
    }

    .cnew {
        color: #9C9C9C;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: 300;
    }

    .tlist {
        color: #727272;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 19px;
    }

    #tcard{
        color: #9C9C9C;
        
    }
    .tcontittle {
        color: #0D424B;
        font-family: 'Maitree', serif;
       
    }
    .tatastittle {
        color: #0D424B;
        font-family: 'Maitree', serif;
      
        font-weight: 400;
    }
    .judul {
        color: #0D424B;
        font-family: 'Maitree', serif;
        
        font-weight: 150;
    }
    .tcon2tittle {
        color: #FFFFFF;
        font-family: 'Maitree', serif;
      
        font-weight: 500;
    }
    .txtittle {
        color: #FFFFFF;
        font-family: 'Maitree', serif;
        font-size: 22px;
    }
    .textcontent{
        color: #535353;
        font-family: 'Roboto', sans-serif;
        
    }
    .tfeature{
        color: #9C9C9C;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 12px;
    }
    .tcomm{
        color: #9C9C9C;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
    }
    .tatas{
        color: #9C9C9C;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 300;
    }

   
    .tdate{
        color: #9C9C9C;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 11px;
        
    }
    .texttittle{
        color: #B28F36;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
         font-weight: 400;
    }
    .box{
    width:470px;
    height:550px;
    background: #0D424B;
    border-radius: 20px;
    }

    .area{
            font-family: 'Montserrat'; 
            font-size: 16px; 
            font-weight: 400;
        }

    .ukuran{
            font-family: 'Montserrat', serif;
            font-size: 18px; 
            font-weight:800;
        }
    .isi{
        font-family: 'Montserrat';
        font-size: 16px; 
        font-weight:400;
    }
    .atasan{
        align-items: center;
        text-align: center
    }

    .ukur{
        font-family: 'Montserrat';
        font-size: 17px;
        color: #0D424B;
        font-weight: 600;
    }

</style>
