<template>
<div>
    <div class="container-fluid py-2" style="background-color: #2E2D2D">
    </div>
    <div class="containerfluid  py-5 " style="background-color: #FFFDEE">
    <div class="container">
      <div class="row pt-3">
        <div class="col-12">
          <h2 class="hubungi text-center">SEGERA HUBUNGI KAMI!</h2>
        </div>
      </div>
      <div class="row py-4">
        <div class="col-12 text-center">
         <a data-bs-toggle="modal" class="d-flex justify-content-center btn-wa-footer" data-bs-target="#exampleModal"><img src="../assets/images/WA-atas.png" class="wa2" alt=""></a>
        </div>
      </div>
    </div>
  </div>
    <div class="container-fluid pt-5" style="background-color: #2E2D2D">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-1"></div>
                <div class="col-12 col-lg-4 my-4 text-lg-start text-center">
                    <img src="../assets/images/Logo-TRANSPARAN WHITE 1.png" class="my-5 logoFooter" alt="">
                </div>
                <div class="col-12 col-lg-2">

                </div>
                <div class="col-12 col-lg-4">
                    <div class="row">
                        <div class="col-12 contact text-center text-lg-start">
                            <h3>CONTACT US</h3>
                        </div>
                    </div>
                    <div class="row py-3 d-flex justify-content-center d-flex justify-content-lg-start">
                        <div class="col-12 col-lg-12 mb-4 mb-lg-0 d-flex justify-content-center d-flex justify-content-lg-start">
                            <a data-bs-toggle="modal" class="d-flex justify-content-center d-flex justify-content-lg-start d-flex justify-content-md-center btn-wa-footer" data-bs-target="#exampleModal">
                                <img src="../assets/images/Group 22.svg" class="wa" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="row pt-3 d-flex justify-content-center d-flex justify-content-lg-start">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-2 col-lg-12"></div>
                                <div class="col-4 col-lg-6 mb-3 mb-lg-0 ">
                                    <a href="https://www.instagram.com/estusae_official/"><img src="../assets/images/instagram-01 1.png" class="ig" alt=""></a>
                                </div>
                                <div class="col-4 col-lg-6 mb-3 mb-lg-0">
                                    <img src="../assets/images/facebook-circular-logo-01 1.png" class="fb" alt="">
                                </div>
                                 <div class="col-2 col-lg-12"></div>
                            </div>
                        </div>  
                    </div>
                    <div class="row pt-3">
                        <div class="col-12 col-lg-6 text-center text-lg-start mb-4 mb-lg-0">
                            <h6 class="by">Developed By :</h6>
                            <a href=""><img class="logoAkhir"  src="../assets/images/Logo develop2-01-01.png" alt=""></a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-1"></div>
            </div>
        </div>
        <div class="container text-center">
            <div class="row pb-2 pt-2 pt-lg-5">
                <div class="col-12 copy">
                    <span>© Copyright All Right Reserved. 2021</span>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-center" style="color : #2E2D2D;" id="exampleModalLabel">Dapatkan Penawaran Menarik Khusus Karyawan AHM Karawang</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3">
                                    <input type="text" class="form-control" v-model="NamaProspect" id="recipient-name" placeholder="Nama">
                                </div>
                                <div class="mb-3">
                                    <input type="number" placeholder="Phone, contoh: 0812800xxxxx" class="form-control" id="message-text" v-model="Hp" >
                                    <small v-if="infoError" style="color:red;">{{infoError}}</small>
                                </div>
                                <div class="mb-3">
                                    <input type="email" class="form-control" id="recipient-name" v-model="EmailProspect" placeholder="Email">
                                </div>
                            </form>
                        </div>
                        <div class="d-grid gap-2 mb-3 mx-3 text-center">
                            <button class="btn btn-success btn-submit-form-brosur" style="background : #2E2D2D; border-color : #2E2D2D;" type="submit" @click="HandleSubmit()">SUBMIT</button>
                        </div>                        
                        <div v-if="infoSuccess" class="alert alert-success mt-1 mb-3 mx-3" role="alert">
                        {{infoSuccess}}
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>

<style scoped>
.hubungi {
    font-family: Manuale;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.2em;

    color: #2E2D2D;

}

.logoAkhir{
    width : 130px;
    height : auto;
    
}
.copy {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 39px;
    /* font-size: 19px;

    display: flex;
    align-items: center;
    text-align: center; */
    letter-spacing: 0.055em;

    color: #FFFFFF;
}

.contact {
    font-family: Mansory;
    font-style: normal;
    font-weight: 600;
    line-height: 0px;
    /* or 0% */
    letter-spacing: 0.1em;

    color: #FFFFFF;

}

.by {
    font-family: 'F37Bolton-Regular';
    font-style: normal;

    letter-spacing: 0.035em;

    color: #FFFFFF;

}

.wa{
    width : 160px;
    height : auto;
    
}
.wa2{
    width : 200px;
    height : auto;
    
}
.ig{
    width : 48px;
    height : auto;
    
}
.fb{
    width : 48px;
    height : auto;
    
}
.logoFooter{
    width : 400px;
    height : auto;
    
}
.email{
    width : 160px;
    height : auto;
    
}
.logoAkhir{
    width : 70%;
    height : auto;
    
}
@media only screen and (max-width: 1030px) {
    .wa{
            width: 220px;
            height: auto;
    }
    .wa2{
            width: 220px;
            height: auto;
    }
    .logoFooter{
            width: 350px;
            height: auto;
    }
    .ig{
    width : 48px;
    height : auto;
    
    }
    .fb{
        width : 48px;
        height : auto;
        
    }
    .email{
            width: 220px;
            height: auto;
    }
    .logoAkhir{
            width: 40%;
            height: auto;
    }
}

@media only screen and (max-width: 700px) {
    .wa{
            width: 200px;
            height: auto;
        }
    .wa2{
            width: 200px;
            height: auto;
        }
        .ig{
    width : 48px;
    height : auto;
    
    }
    .fb{
        width : 48px;
        height : auto;
        
    }
    .logoFooter{
            width: 320px;
            height: auto;
        }
    .email{
            width: 200px;
            height: auto;
        }
    .logoAkhir{
            width: 45%;
            height: auto;
        }
}
</style>
<script>
export default {
  name: 'Footer',

  data(){
        return{

            NamaProspect : '',
            EmailProspect : '',
            Hp : '',
            infoError : '',
            infoSuccess : '',
            VerifiedStatus :''
                    
        }
  },
  created(){
    },
    methods: {
        HandleSubmit(){
             let config = {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    'Content-type' : 'application/json',
                    'Access-Control-Allow-Methods' : 'GET, POST',
                    'Access-Control-Allow-Headers' : 'X-Requested-With',
                    'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
                },
            }
           let formData = new FormData()
            formData.append('NamaProspect', this.NamaProspect)
            formData.append('Hp', this.Hp)
            formData.append('EmailProspect', this.EmailProspect)
             formData.append('KodePT', 'BEP')
            formData.append('KodeProject', 'AHM')
            formData.append('VerifiedStatus', 1)


            this.axios.post('https://api.makutapro.id/prospect/website', formData, config)
            .then(response=>{
                if(response.data.message == "Nomor Handphone Sudah terdaftar"){
                   this.infoError=response.data.message
                }else{
                  this.NamaProspect = '',
                  this.EmailProspect = '',
                  this.Hp = '',
                  this.infoSuccess = 'Terimakasih. Kami akan segera menghubungi Anda',
                  window.open('https://wa.me/6281292083738?text=Halo%2C+Saya+tertarik+Estusae+Alam+Indah+Cikopo+dari+website.+Boleh+infokan+promo+khusus+AHM+Karawang+dan+detilnya%3F', '_blank');
                  
                }
               
            })
        }
    }
}
</script>