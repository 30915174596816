<template>
  <div class="home mt-0 mt-md-0 mt-lg-5">
      <div class="container-fluid pt-4 pt-md-0 pt-lg-0  px-0 mx-0" style="background-color: #FFFDEE">
        <div id="carouselExampleControls" class="carousel slide mx-0 px-0" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../assets/images/Banner Web AHM 1366x653px-01.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/images/WhatsApp Image 2021-12-30 at 08.02 1.png" class="d-block w-100" alt="...">
            </div>
            
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="container-fluid py-3 py-lg-5 px-0 mx-0" style="background-color: #FFFDEE">
        <div class="container px-4  px-md-4 px-lg-0 mt-5">
          <div class="row d-flex justify-content-center pb-3 pb-lg-5">
            <div class="col-12 col-md-12 col-lg-7 mb-3 mb-md-3 mb-lg-0">
              <hr  style="border-top: 4px solid #B28F36; width: 40px; opacity:100">
                <h3 class="tcontittle mb-lg-3 mb-0">Keunggulan tinggal <br>di Estusae Alam Indah Cikopo</h3>
                <ul type="none" class="tlist mx-0 px-0" style="line-height: 29px">
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Hunian Premium seluas 24 Ha</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Akses Terbaik : Pusat Industri Cikampek & Cikarang</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Area Hijau Terkoneksi dengan Garden Concept</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Internet by Indihome</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Design Modern</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Nilai Investasi Tinggi di Masa Depan</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Konsep Rumah Tumbuh</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> ROW Jalan Lebar</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> 200+ KK Hunian Aktif</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Jogging Track</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Swimming Pool</li>
                </ul>
            </div>
            <div class="col-lg-5 col-12 col-md-12 pb-5 pt-4 px-4 " style="background : #A8A068; border-radius: 15px;">
              <div class="row">
                <div class="col-12 py-0">
                  <hr class=""  style="border-top: 4px solid #2E2D2D; width: 40px; opacity:100">
                  <h1 style="font-family: Maintree; color: #FFFFFF">Dapatkan Penawaran Menarik Khusus Karyawan AHM Karawang</h1>
                </div>
              </div>
              <div class="row py-2">
                  <div class="col-12">
                    <span class="formisi" >Silahkan isi data dibawah ini : </span>
                  </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div v-if="infoSuccess" class="alert alert-success mt-1 mb-3 mx-3" role="alert">
                    {{infoSuccess}}
                    </div>
                  <div class="form-floating mb-3 tinput">
                    <input type="email" required="" class="form-control" id="floatingInput" v-model="NamaProspect"  placeholder="Nama Lengkap">
                    <label for="floatingInput">Nama Lengkap</label>
                  </div>
                  <div class="form-floating mb-3 tinput">
                    <input type="number" required class="form-control" id="floatingInput" v-model="Hp" placeholder="Nomer Handphone" >
                     <small v-if="infoError" style="color:red;">{{infoError}}</small>
                    <label for="floatingInput">Nomer Handphone</label>
                  </div>
                  <div class="form-floating mb-3 tinput">
                    <input type="email" class="form-control" id="floatingInput" v-model="EmailProspect" placeholder="Alamat Email">
                    <label for="floatingInput">Alamat Email</label>
                  </div>
                  <div class="form-floating  mb-3 tinput">
                      
                      <select  class=" form-select " aria-label="Default select example" id="floatingInput" v-model="Message">
                        <option value="900 Ribu">900 Ribu</option>
                        <option value="1,2 - 1,5 Juta">1,2 - 1,5 Juta</option>
                        <option value="1,5 - 2,5 Juta">1,5 - 2,5 Juta</option>
                        <option value="> 2,5 Juta">> 2,5 Juta</option>
                      </select> 
                      <label for="floatingInput"><strong>Pilihan Cicilan Per-Bulan</strong> </label>
                  </div>
                  
                <button id="getpromo" class=" w-100 btn btn-lg kirim btn-form-brosur" style="background-color: #0F8C1C" @click="HandleSubmit()">Kirim</button> 
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

  <HomeResidential/>
  <Area/>  
  <HomeLocation/>
  <!-- <HomeNew/> -->
  <Footer/>
</div>
</template>

<script>

// import HomeNew from '../components/HomeNew.vue'
import Area from '../components/Area.vue'
import HomeLocation from '../components/HomeLocation.vue'

import HomeResidential from '../components/HomeResidential.vue'


export default {
  components: { Area, HomeLocation, 
  // HomeNew, 
   HomeResidential},
  name: 'Home',
  mounted () {
    window.scrollTo(0, 0)
  },
  data(){
        return{
            slides:[],
            NamaProspect : '',
            EmailProspect : '',
            Hp : '',
            Message : '',
            infoError : '',
            infoSuccess : ''            
        }
  },
  created(){
    this.$axios.get('https://adm-estusae.makutapro.id/api/slide')
      .then((response) => {
        this.slides = response.data.data
       })
    },
    methods: {
        HandleSubmit(){
             let config = {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    'Content-type' : 'application/json',
                    'Access-Control-Allow-Methods' : 'GET, POST',
                    'Access-Control-Allow-Headers' : 'X-Requested-With',
                    'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
                },
            }
            let formData = new FormData()
            formData.append('NamaProspect', this.NamaProspect)
            formData.append('Hp', this.Hp)
            formData.append('EmailProspect', this.EmailProspect)
            formData.append('Message', this.Message)
             formData.append('KodePT', 'BEP')
            formData.append('KodeProject', 'AHM')
            formData.append('VerifiedStatus', 1)


            this.axios.post('https://api.makutapro.id/prospect/website', formData, config)
            .then(response=>{
                if(response.data.message == "Nomor Handphone Sudah terdaftar"){
                    this.infoError=response.data.message
                }else if(response.data.message == "Nomor Handphone Salah"){
                    this.infoError=response.data.message
                }else{
                  this.NamaProspect = '',
                  this.EmailProspect = '',
                  this.Hp = '',
                  this.Message = '',
                 this.infoSuccess = 'Terimakasih. Kami akan segera menghubungi Anda'
                }
            })
        }
    }
}
</script>

<style scoped>
.boxx{
width: 100%;
background: #0D424B;
border-radius: 20px;
}

.tinput{
font-family: 'Roboto';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.1em;
color: rgba(0, 0, 0, 0.3);
}

.formisi {
  font-family: Roboto;
font-style: normal;
font-weight: normal;

line-height: 21px;
letter-spacing: 0.1em;

color: #FFFFFF;
}

.kirim{
  font-family: Roboto;
font-style: normal;
font-weight: bold;
line-height: 42px;
text-align: center;
letter-spacing: 0.1em;

color: #FFFFFF;

}
.tlist{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 33px;
letter-spacing: 0.005em;

color: #727272;

}

.tcontittle{
  font-family: Manuale;
font-style: normal;
font-weight: bold;

text-align: justify;
letter-spacing: 0.06em;

color: #2E2D2D;
}

.box2{
  position: absolute;
width: 574px;
height: 83px;
background: #0F8C1C;
border-radius: 20px;
}

.imgrad{
  width: 100%;
  border-radius: 176px 0px 0px 0px;
}
@media only screen and (max-width: 1000px) {
    .imgrad{
        width:100%;
        border-radius: 0px 0px 0px 0px;
    }
    
}
@media only screen and (max-width: 700px) {
    .imgrad{
        width: 100%;
        border-radius: 0px 0px 0px 0px;
    }
    .tlist{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.005em;

    color: #727272;

    }
}
</style>