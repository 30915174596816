<template>
<div class="">
    <div class="container-fluid  py-5" style="background-color: #2E2D2D">
        <div class="container py-2 text-center">
            <div class="row">
                <div class="col-12 textunit">
                    <h1>TIPE UNIT</h1>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-12 col-lg-4 my-3 my-lg-0" v-for="home in homes" :key="home.index">
                    <router-link :to="{ name: 'detail_units', params: { slug: home.slug}}" class="nav-link px-0 mt-0">
                        <div class="" >
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+home.img_layout" class="card-img-top" alt="">
                            <div style="background-color:#FFFDEE">
                                <div class="ps-2 pt-1 pita " style="width:220px; height:32px;"><p class="pita1 ">&nbsp;Harga Mulai&nbsp;<span class="pita2"><strong>{{home.price}}</strong>&nbsp;Jt-an</span></p></div>
                            </div>
                            <div class="card-body bgCard mx-0 my-0">
                                <h5 class="card-title textCard">{{home.name}}</h5>
                            </div>
                        </div>
                    </router-link>
                </div>                
            </div>
        </div>
    </div>
    <div class="container-fluid py-3 textArea" style="">
        <div class="container ">
                <div class="row text-center">
                    <div class="col-12 ">
                        <h1>AREA KOMERSIL</h1>
                    </div>
                </div>
            </div>
    </div>
    <div class="container-fluid px-0 mx-0 img ">
        <router-link :to="{ name: 'type72' }" class="nav-link px-0 mt-0 py-0 my-0">
            <img src="../assets/images/ruko high res 1.png" class="img-fluid " width="100%" alt="">
        </router-link>
    </div>
</div>
</template>

<style scoped>

.bgimg{
    background-image: url("../assets/images/AreaBg.png");
    width:100%;
    height:1000%;
    background-size:cover;
    background-repeat:no-repeat;
    padding-bottom: 1000px;
}
.bgCard {
    background-color: #FFFDEE;
    border: none;
}

.textCard {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.25em;
    color: #724603;

}

.textunit {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.35em;
    color: #FFFDEE;
}
.textArea {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.35em;
    background-color : rgba(168, 160, 104, 0.8); 
    background-blend-mode: multiply;
    color: #FFFDEE;
    z-index: 1;
    position: absolute;
}
.img{
    z-index: 0;
}
</style>

<script>
export default {
     name: 'home_news',
    data(){
        return{
            homes:[],
        }
    },
    mounted () {
    window.scrollTo(0, 0)
  },
  created(){
     this.$axios.get('https://adm-estusae.makutapro.id/api/contentunit')
      .then((response) => {
        this.homes = response.data.data
       })
  }
}
</script>